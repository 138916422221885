<template>
    <div class="table-container">
        <b-table
            :fields="fileFields"
            :items="files"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header="true"
            no-border-collapse
        >
            <template #thead-top="data">
                <b-tr class="text-center">
                    <b-th colspan="4">Прикрепляемые документы</b-th>
                </b-tr>
            </template>
            <template #cell(type)="data">
                <div></div>
            </template>
            <template #cell(fileName)="data">
                <b-link target="_blank" href="#" @click="downloadFile(data.item.id, data.item.fileName)">{{ data.value }}</b-link>
            </template>
            <template #cell(description)="data">
                <b-form-textarea
                    id="textarea"
                    v-model="data.item.description"
                    placeholder="Добавить описание (название документа, номер, дата)..."
                    rows="5"
                    :disabled="reading">
                </b-form-textarea>
            </template>
            <template #cell(more)="data">
                <b-button :disabled="reading" @click="confirmDeletion(data.item.id)">
                    <i class="icon icon-close"></i>
                </b-button>
            </template>
            <template #bottom-row="data">
                <td></td>
                <td>
                    <!-- TODO: ? limit accept -->
                    <b-form-file
                        size="sm"
                        id="file"
                        ref="file-input"
                        accept="*.*"
                        v-model="inputFile"
                        :disabled="reading"
                    />
                </td>
                <td>
                    <b-button variant="success" @click="uploadFile" :disabled="(reading && !inputFile)">Загрузить</b-button>
                    <p class="upload-btn-label" style="margin-top: 5px;">(Максимальный размер: 50 MB)</p>
                    <a href="#" class="nav-link download-all" @click="downloadAllFile">Скачать все
                        <span>({{(files && files.length > 0) ? files.length : 0}})</span></a>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { Ax } from '@/utils'
import store from "@/services/store"
import axios from "axios"

export default {
    name: 'FileLoad',
    props: {
        agreementNumber: null,
        agreementId: null,
        reading: null,
        code: null,
        files: []
    },
    data() {
        return {
            fileFields: [
                {
                    key: 'type',
                    label: ''
                },
                {
                    key: 'fileName',
                    label: ''
                },
                {
                    key: 'description',
                    label: ''
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            inputFile: null,
            text: ''
        }
    },

    methods: {
        async confirmDeletion(id) {
            this.$bvModal.msgBoxConfirm(
                'Удалить выбранный файл?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.$emit('removeFile', id)
                    }
                })
                .catch(error => {
                    this.makeToast('Не удалось удалить файл', 'Ошибка удаления', 'danger')
                    console.error(error.toString())
                })

        }, // диалоговое окно для удаления файла

        // TODO: ! remove; unused
        async deleteFile(id) {
            try {
                const response = await fetch(`api/budget-loan/delete-storage-files?fileIds=${id}`, { method: 'DELETE' })
                if (!response.ok) {
                    const error = await response.json()
                    console.error(error)
                    this.makeToast('Не удалось удалить файл', 'Ошибка', 'danger')
                    return
                }

                this.$emit('removeFile', id)
                this.makeToast('Файл успешно удален', 'Сообщение', 'success')
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления файла')
            }
        }, // удаляет файл с хранилища

        downloadFile(id, fileName) {
            Ax(
                {
                    url: `api/budget-loan/storage-download/${id}`,
                    method: 'GET',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    // TODO: ? if other extenstions other than 'pdf' should be supported
                    const blob = new Blob([data], { type: 'application/pdf' })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                },
                (error) => {
                    this.makeToast('Не удалось скачать файл', 'Ошибка', 'danger')
                    console.error(error.toString())
                }
            )
        }, // скачать файл

        downloadAllFile() {
            const ids = this.files
                .map(item => item.id)
                .reduce((previous, current) => {return previous+`${current},`}, '')
                .slice(0, -1)
            Ax(
                {
                    url: `api/budget-loan/storage-download-zip?fileIds=${ids}`,
                    method: 'GET',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    // TODO: ? if other extenstions other than 'pdf' should be supported
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${this.agreementNumber}.zip`)
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                },
                (error) => {
                    this.makeToast('Не удалось скачать файл', 'Ошибка', 'danger')
                    console.error(error.toString())
                }
            )
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true
            })
        },

        uploadFile() {
            if (this.inputFile) {
                this.$emit('addFile', this.inputFile)
                this.inputFile = null
            } else {
                this.makeToast('Необходимо выбрать файл для загрузки', 'Ошибка', 'danger')
            }
        }
    }
}
</script>

<style>
.upload-btn-label {
    font-size: 0.6rem;
    font-style: italic;
    opacity: 0.6;
}
</style>